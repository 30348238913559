import { useEffect, useState } from "react";
import { useDeepCompareEffectNoCheck } from "use-deep-compare-effect";

export const useDebounce = <T,>(value: T, delay = 500) => {
	const [debouncedValue, setDebouncedValue] = useState(value);
	const [isDebouncing, setIsDebouncing] = useState(false);

	useDeepCompareEffectNoCheck(() => {
		setIsDebouncing(true);
		const timeout = setTimeout(() => {
			setDebouncedValue(value);
			setIsDebouncing(false);
		}, delay);

		return () => {
			clearTimeout(timeout);
			setIsDebouncing(false);
		};
	}, [value, delay]);

	return { value: debouncedValue, isDebouncing };
};

export const useDebunceCallback = (initCallback?: () => void, delay = 1000) => {
	const [callback, setCallback] = useState<(() => void) | undefined>(
		() => initCallback,
	);
	const [timeoutId, setTimeoutId] = useState<
		ReturnType<typeof setTimeout> | undefined
	>(() => undefined);

	useEffect(() => {
		if (timeoutId) {
			clearTimeout(timeoutId);
		}

		if (!callback) {
			return;
		}

		const id = setTimeout(() => {
			callback();
		}, delay);

		setTimeoutId(() => id);

		return () => {
			if (timeoutId) {
				clearTimeout(timeoutId);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [callback]);

	return (cb?: () => void) => setCallback(() => () => cb?.());
};
