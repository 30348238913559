import { useEffect, useMemo, useState } from "react";
import { useInView } from "react-intersection-observer";
import { Loader } from "../Utility/Loader";
import { LoadingFailure } from "../Utility/LoadingFailure";
import { MatchGrid } from "./MatchGrid";
import { Heading } from "@withjuly/solis";
import { trpc } from "../Utility/trpc";
import { QueueConsole } from "./QueueConsole";
import { useRouter } from "next/router";
import { PitchModal } from "../Pitch/PitchModal/PitchModal";
import { useFeatureFlagEnabled } from "posthog-js/react";

export const AllMatches: React.FC = () => {
	const isBrandBookEnabled = useFeatureFlagEnabled("brand-book");
	const [isBrandsWithBudgetsPitchOpen, setIsBrandsWithBudgetsPitchOpen] =
		useState(() => true);
	const router = useRouter();
	const { ref, inView } = useInView();
	const { data: wishlistMatches } = trpc.match.wishlist.useQuery();
	const { data: brandsWithBudget } =
		trpc.match.getMatchedBrandsWithBudget.useQuery(undefined, {
			enabled: isBrandBookEnabled,
		});
	const {
		data,
		error,
		refetch,
		fetchNextPage,
		isFetchingNextPage,
		hasNextPage,
	} = trpc.match.paginated.useInfiniteQuery(
		{ limit: 5 },
		{
			refetchOnWindowFocus: false,
			getNextPageParam: (lastPage) => {
				const nextOffset = lastPage.offset + lastPage.size;
				if (nextOffset >= lastPage.total) {
					// Returning undefined means we don't have any more pages
					return undefined;
				}
				return nextOffset;
			},
		},
	);
	const brandsWithBudgetsUuid = useMemo(() => {
		if (router.isReady) {
			const { bwb } = router.query;
			if (typeof bwb === "string") {
				return bwb;
			}
		}

		return null;
	}, [router]);
	const { data: brandWithBudgetMatch } =
		trpc.match.getBrandsWithBudgetMatch.useQuery(
			{
				uuid: brandsWithBudgetsUuid ?? "",
			},
			{
				enabled: !!brandsWithBudgetsUuid,
			},
		);

	useEffect(() => {
		if (inView && !isFetchingNextPage) {
			fetchNextPage();
		}
	}, [inView, fetchNextPage, isFetchingNextPage]);

	const matches = useMemo(() => {
		const paginatedMatches = (data?.pages ?? []).flatMap(({ items }) => items);

		if (isBrandBookEnabled) {
			return [...(brandsWithBudget ?? []), ...paginatedMatches];
		} else {
			return paginatedMatches;
		}
	}, [brandsWithBudget, data?.pages, isBrandBookEnabled]);

	if (error) {
		return <LoadingFailure onRetry={refetch} />;
	}

	if (!data) {
		return <Loader />;
	}

	return (
		<>
			<div className="relative flex flex-col gap-6 pb-[70px] pt-4 md:pb-8">
				<div className="z-20 flex items-center justify-between bg-gray-900 ">
					<div className="flex items-center gap-2">
						<Heading variant="h4">Matches</Heading>
						<p className="pt-1">{matches.length}</p>
					</div>
				</div>
				<MatchGrid
					brands={[...(wishlistMatches?.brands ?? []), ...matches]}
					totalMatches={data.pages[0]?.total}
					inViewRef={matches.length > 0 && hasNextPage ? ref : undefined}
				/>
			</div>
			{/* Mobile Queue Console */}
			<div className="fixed bottom-0 left-0 z-10 w-full md:hidden">
				<QueueConsole />
			</div>
			{/* Desktop Queue Console */}
			<div className="fixed bottom-0 right-0 z-10 m-8 hidden md:block">
				<QueueConsole />
			</div>
			{brandWithBudgetMatch ? (
				<PitchModal
					isOpen={isBrandsWithBudgetsPitchOpen}
					setIsOpen={setIsBrandsWithBudgetsPitchOpen}
					showStrategyOptions={false}
					brands={[brandWithBudgetMatch]}
					isBrandWithBudget
				/>
			) : null}
		</>
	);
};
